import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { BiEdit, BiTrash } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../features/product/productSlice";

const columns = [
  {
    title: "#",
    dataIndex: "key",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.key - b.key,
  },
  {
    title: "Title",
    dataIndex: "title",
    sorter: (a, b) => b.title.localeCompare(a.title),
    sortDirections: ["descend"],
  },
  {
    title: "Price",
    dataIndex: "price",
    sorter: (a, b) => a.price.length - b.price.length,
    sortDirections: ["descend"],
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    defaultSortOrder: "descend",
    sorter: (a, b) => a.quantity - b.quantity,
  },
  // {
  //   title: "Category",
  //   dataIndex: "category",
  //   sorter: (a, b) => b.title.localeCompare(a.title),
  //   sortDirections: ["descend"],
  // },
  // {
  //   title: "Brand",
  //   dataIndex: "brand",
  //   sorter: (a, b) => b.title.localeCompare(a.title),
  //   sortDirections: ["descend"],
  // },
  // {
  //   title: "Color",
  //   dataIndex: "color",
  // },
  {
    title: "Action",
    dataIndex: "action",
  },
];

const ProductList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const productState = useSelector((state) => state.product.products);
  const data1 = [];
  if(productState && productState.data && productState.data.length){
    for (let i = 0; i < productState.data.length; i++) {
      data1.push({
        key: i + 1,
        title: productState.data[i].title,
        price: `$${productState.data[i].price}`,
        quantity: productState.data[i].quantity,
        action: (
          <>
            <Link className="text-danger fs-5" to="/">
              <BiEdit />
            </Link>
            <Link className="text-danger ms-3 fs-5" to="/">
              <BiTrash />
            </Link>
          </>
        ),
      });
    }
  }

  return (
    <div>
      <h3 className="mb-4 title">Products</h3>
      <div>
        <Table columns={columns} dataSource={data1} />
      </div>
    </div>
  );
};

export default ProductList;
