import axios from "axios";
import { base_url } from "../../utils/baseUrl";

const getUsers = async () => {
  // const response = await axios.get(`${base_url}user/all-users`);
  const response = await axios.get(`/json/customers.json`);
  //console.log(response.data);

  return response.data;
};

const customerService = {
  getUsers,
};

export default customerService;
