import axios from "axios";
import { config } from "../../utils/axiosconfig";
import { base_url, BASE_URL } from "../../utils/baseUrl";

const getProducts = async () => {
  const response = await axios.get(`${BASE_URL}products/list?producttype=main&limit=30`);
  //console.log(response.data);
  return response.data;
};

const createProduct = async (product) => {
  const response = await axios.post(`${BASE_URL}products/add-product`, product, config);
  //console.log(response.data);
  return response.data;
};

const productService = {
  getProducts,
  createProduct,
};

export default productService;
